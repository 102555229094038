<template>
    <div>
        <Breadcrumb titulo="MENSAGENS S/ Protocolo" :items="itensBreadcrumb" backgroundColor="#666" />
        <div class="container-fluid py-4" style="font-size:12px;" >
            <b-row>
                <b-col col-12 >
                    <b-row style="margin-right: 0px; margin-left: 0px; background-color: #e8e8e8; padding: 12px 0px">
                        <b-col>
                            <table style="width:100%; display: table">
                                <tr>
                                    <td>Data inicial</td>
                                    <td><input-data-tabela v-model="dataInicial" placeholder="Data inicial" style="margin: 0px 0px 0px -30px; " /></td>
                                    <td>Data Final</td>
                                    <td><input-data-tabela v-model="dataFinal" placeholder="Data final" style="margin: 0px 0px 0px -30px; " /></td>
                                </tr>
                            </table>
                        </b-col>
                        <b-col>
                            Ação &nbsp;&nbsp;
                            <select v-model="acaoSelecionada" style="padding: 5px; border: 1px solid #ccc;">
                                <option>Escolha...</option>
                                <option v-for="(item, index) in acoes" :value="item.posicao">{{item.nome}}</option>
                            </select>
                            <div style="display: inline">
                                <span class="btn_filtrar_mensagem" id="btn_filtrar_mensagem" @click="filtar_mensagem()">Filtrar</span>
                            </div>
                        </b-col>
                        <b-col>
                            
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-sm" style="background: #e8e8e8; margin-left: 15px; margin-right: 15px">
                            <div class="segura_tabela_modulos">
                                <table class="table b-table table-striped table-sm ">
                                    <thead>
                                        <tr style="background: #FFF">
                                            <th>Mensagem</th>
                                            <th>Data envio</th>
                                            <th>Origem</th>
                                            <th>De</th>
                                            <th>Para</th>
                                        </tr>
                                    </thead>
                                    <tr v-for="(item, index) in listaMensagem.List" :class="computedClass(index)">
                                        <td>
                                            <div class="abrir_mensagem_padrao" v-on:click='abrirMensagem(item.Mensagem)'>
                                                <i class='far fa-comment-alt fa-2x'></i>
                                            </div>
                                        </td>
                                        <td>{{item.DataEnvio}} </td>
                                        <td>{{item.Origem}} </td>
                                        <td>{{item.De}} </td>
                                        <td>{{item.Para}} </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <b-row id="rodape_consulta_mensagem">
                        <b-col>
                            <span class="next_before" @click="filtar_mensagem('ant')"><</span> {{current_page}} de {{qnt_paginas}} <span class="next_before" @click="filtar_mensagem('prox')">></span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <MensagensSProtocolo :mensagem ="mensagemAbrir" ref="MensagensSProtocolo"/>
</div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import RelatoriosServices from '@/assets/js/services/RelatoriosConsultasServices'
    import InputDataTabela from '@/components/inputs/InputDataTabela.vue'
    import MensagensSProtocolo from '@/components/popups/MensagensSProtocolo.vue'
    export default {
        name: 'ConsultaMensagensPage',

        components: {
            Breadcrumb,
            MensagensSProtocolo,
            InputDataTabela
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-search',
                        texto: 'Consultas mensagens',
                        link: ''
                    },
                ],
                loadingTabela: false,
                mensagensConsulta: [],
                mensagensExportacao: [],
                filtrosIniciais: null,
                totalDeResultados: 0,
                listaMensagem: [],
                dadosConversa: '',
                dataInicial: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                dataFinal: (new Date()).toLocaleDateString().split('/').reverse().join('-'),
                pagina: 0,
                texto_filtragem: null,
                qnt_paginas: 1,
                current_page: 0,
                acoes: [{ nome: "Enviados", posicao: 0 }, { nome: "Recebidos", posicao: 1 }],
                acaoSelecionada: null,
                mensagemAbrir: ''
            }
        },
        created() {
            this.filtar_mensagem();
        },
        methods: {
            carregaConversaV2: async function () {
                $("#caixa_carregamento").show()
                this.listaConversa = await RelatoriosServices.carregaConversaV2();
                $("#caixa_carregamento").hide()
            },
            computedClass(index) {
                if (index % 2 != 0) {
                    return "linha_par"
                }
            },
            abrirMensagem: function (mensagem) {
                this.mensagemAbrir = mensagem
                //this.$refs.MensagensSProtocolo.show()
                this.$refs.MensagensSProtocolo.mostrarPopup();
                console.log(this.acaoSelecionada)
            },
            filtar_mensagem: async function (acao) {
                console.log(this.acaoSelecionada)
                if (acao == 'prox') {
                    this.pagina++
                }
                if (acao == 'ant') {
                    if (this.pagina - 1 <= 0) { this.pagina = 0 } else { this.pagina-- }
                }
                var dados = "";
                if (this.texto_filtragem != null) {
                    dados += "Mensagem=" + this.texto_filtragem + "&";
                }
                if (this.acaoSelecionada != null) {
                    dados += "Acao=" + this.acaoSelecionada + "&";
                }
                dados += "Limit=10&DataInicial=" + this.dataInicial + "&DataFinal=" + this.dataFinal + "&Offset=" + this.pagina + "&Sort=-Inclusao"
                $("#caixa_carregamento").show()
                this.listaMensagem = await RelatoriosServices.carregaMensagemSProtocolo(dados);
                $("#caixa_carregamento").hide()
                if (this.listaMensagem.List.length > 0) {
                    this.current_page = (this.listaMensagem.CurrentPage + 1)
                    this.qnt_paginas = Math.ceil((this.listaMensagem.TotalResults / 10));

                }
            }
        }
    }
</script>

<style scoped>
    .consulta-mensagens-page-tabela {
        height: calc(100vh - 180px);
    }
    .linha_par {
        background-color: #f5f5f5;
    }

    .menu_consulta_conversa {
        margin-left: 0px;
    }

    .input_data {
        display: inline-flex;
        width: 25%;
        margin-right: 15px;
    }

    .input_search_text {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 60%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }

    .btn_filtrar_mensagem {
        cursor: pointer;
        width: 118px;
        text-align: center;
        padding: 5px 1px;
        position: absolute;
        background-color: #19b1bd;
        color: #FFF;
        margin-left: 40px;
    }

    .next_before {
        padding: 6px;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
    }

    .abrir_mensagem_padrao {
        cursor: pointer;
    }
</style>